<template>
  <div id="calendarMain">
    <div v-if="es_doctorclic">
      <calendar-specialties />
    </div>
    <div v-else>
      <calendar-professionals />
    </div>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { nameEnv } from '../config/config';
import CalendarSpecialties from '../components/common/CalendarSpecialties.vue';
import CalendarProfessionals from '../components/common/CalendarProfessionals.vue';

export default {
  components: {
    CalendarProfessionals,
    CalendarSpecialties,
  },
  data: () => ({
    especialidad: 0,
    tab: 0,
    centro: 1,
    tabla: { headers: [], body: [] },
    dialogSalirSinGuardar: false,
    pageNext: {},
    dias: [],
    menu: false,
    time: null,
    agendaModificada: false,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    es_doctorclic: nameEnv === 'doctorclic',
  }),

  methods: {
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.agendaModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
