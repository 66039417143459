<template>
  <div id="calendarSpecialties">
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar color="secondary" dark text dense cad flat>
              <v-toolbar-title class="subheading">{{ $t('calendario.especialidades') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />
            <v-card-text class="">
              <v-layout class="filtroInforme">
                <v-flex xs6>
                  <v-select
                    v-model="especialidad"
                    :items="especialidades"
                    :label="$t('agenda.especialidad')"
                    auto
                    required
                    :item-text="$i18n.locale === 'es' ? 'name_es' : 'name_en'"
                    item-value="id"
                    @change="cambiaEspecialidad"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-select
                    v-model="centro"
                    :items="centros"
                    :label="$t('common.centro')"
                    auto
                    required
                    item-text="name"
                    item-value="id"
                    @change="cambiaEspecialidad"
                  />
                </v-flex>
              </v-layout>
              <span v-if="typeof tabla.calendario !== 'undefined'">
                <v-toolbar flat dense color="transparent">
                  <v-btn icon @click="modificarFecha('before', tabla.calendario.primer_dia_semana)"
                    ><v-icon color="info">mdi-chevron-left</v-icon></v-btn
                  >
                  <v-spacer />
                  {{
                    $t('common.semana') +
                      ' ' +
                      tabla.calendario.primer_dia_front +
                      ' - ' +
                      tabla.calendario.ultimo_dia_front
                  }}
                  <v-spacer />
                  <v-btn icon @click="modificarFecha('after', tabla.calendario.ultimo_dia_semana)"
                    ><v-icon color="info">mdi-chevron-right</v-icon></v-btn
                  >
                </v-toolbar>
                <tabla-semanal
                  :selectors="profesionales"
                  :label_selector="$t('common.centro')"
                  :loading="loading"
                  :headers-tabla="tabla.headers"
                  :items-tabla="tabla.body"
                  class-table="dividirColumnas"
                />
              </span>
              <v-alert v-else color="grey" :value="true">
                {{ mensaje_precarga }}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TablaSemanal from '@/components/common/TablaSemanal.vue';
import { nameEnv, calendarioEspecialidadIndex, getCalendarioEspecialidad, getHeader } from '../../config/config';

export default {
  components: {
    TablaSemanal,
  },
  data: () => ({
    especialidades: [],
    especialidad: 0,
    tab: 0,
    centros: [
      { id: 1, name: 'Madrid' },
      { id: 2, name: 'Barcelona' },
    ],
    centro: 1,
    profesionales: [],
    duracion: 0,
    tabla: { headers: [], body: [] },
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    agenda_id: 0,
    dias: [],
    menu: false,
    time: null,
    mensaje_precarga: '',
    agendaModificada: false,
    disabledButton: false,
    arrayEvents: null,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    fecha: '',
    cambiarFecha: '',
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
    ],
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
    colorPrimario:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== ''
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['color']
        : 'blue',
    es_doctorclic: nameEnv === 'doctorclic',
  }),
  watch: {},

  mounted: function() {
    const tieneAcceso =
      this.es_doctorclic &&
      (this.authUser.rol === 4 ||
        this.authUser.nombreEmpresa === 'AXA_BARCELONA' ||
        this.authUser.nombreEmpresa === 'AXA_MADRID')
        ? true
        : false;
    if (!tieneAcceso) {
      this.$router.push('/dashboard');
    } else {
      this.mensaje_precarga = this.$t('agenda.seleccione_especialidad');
      if (this.authUser.rol !== 4) {
        // mostramos solo el centro al que pertenece el profesional
        if (this.authUser.nombreEmpresa === 'AXA_BARCELONA') {
          this.centros = [{ id: 2, name: 'Barcelona' }];
          this.centro = 2;
        } else {
          this.centros = [{ id: 1, name: 'Madrid' }];
        }
      }
      this.$http
        .post(calendarioEspecialidadIndex, { user_id: this.authUser.id, accion: 'index' }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.loading = false;
            this.especialidades = response.data.especialidades;
            this.profesionales = response.data.profesionales;
            this.profesionales.unshift({ id: 0, name: '-' });
            if (this.especialidades.length === 0) {
              this.mensaje_precarga = this.$t('agenda.sin_horas_asignadas');
            } else if (this.especialidades.length === 1) {
              this.especialidad = this.especialidades[0].id;
              this.cambiaEspecialidad();
            }
          }
        });
    }
  },

  methods: {
    cambiaEspecialidad() {
      if (this.especialidad > 0) {
        this.loading = true;
        this.$http
          .post(
            getCalendarioEspecialidad,
            {
              user_id: this.authUser.id,
              accion: 'calendarioEspecialidad',
              especialidad_id: this.especialidad,
              centro_id: this.centro,
              cambiarFecha: this.cambiarFecha,
              fecha: this.fecha,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.loading = false;
              this.tabla = response.data.agenda;
              this.duracion = response.data.duracion;
            }
          });
      }
    },

    modificarFecha(accion, fecha) {
      this.fecha = fecha;
      this.cambiarFecha = accion;
      this.cambiaEspecialidad();
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },

  modificandoDatos(_val) {
    this.agendaModificada = true;
  },

  beforeRouteLeave(to, from, next) {
    if (this.agendaModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-picker__title primary {
  display: none !important;
}

.tabla_cal th {
  text-align: center;
  color: #27b0e9;
  font-weight: 100;
  font-size: 12px;
}
</style>
