<template>
  <div id="tablaSemanal">
    <v-data-table
      :headers="headersTabla"
      :items="itemsTabla"
      :loading="loading"
      :loading-text="$t('common.cargando')"
      :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
      :class="'elevation-1 filtroInforme ' + classTable"
      :items-per-page-text="$t('datatable.rows_per_page')"
      item-key="name"
      :options.sync="pagination"
    >
      <template v-slot:item="props">
        <tr>
          <td v-if="headersTabla.length">{{ props.item.hora }}</td>
          <td :class="props.item.lunes_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[0]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 0)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[1])"
            >
              {{ props.item.cita[0] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.lunes }}</span>
              <span>{{ props.item.lunes_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.martes_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[1]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 1)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[1])"
            >
              {{ props.item.cita[1] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.martes }}</span>
              <span>{{ props.item.martes_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.miercoles_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[2]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 2)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[2])"
            >
              {{ props.item.cita[2] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.miercoles }}</span>
              <span>{{ props.item.miercoles_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.jueves_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[3]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 3)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[3])"
            >
              {{ props.item.cita[3] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.jueves }}</span>
              <span>{{ props.item.jueves_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.viernes_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[4]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 4)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[4])"
            >
              {{ props.item.cita[4] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.viernes }}</span>
              <span>{{ props.item.viernes_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.sabado_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[5]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 5)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[5])"
            >
              {{ props.item.cita[5] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.sabado }}</span>
              <span>{{ props.item.sabado_doctors }}</span>
            </v-tooltip>
          </td>
          <td :class="props.item.domingo_style">
            <span v-if="props.item.selector">
              <v-select
                v-model="props.item.profesional[6]"
                :items="selectors"
                single-line
                auto
                required
                item-text="name"
                item-value="id"
                class="selectorTabla"
                @change="seleccionaItem(props.index, 6)"
              />
            </span>
            <span
              v-else-if="typeof props.item.cita !== 'undefined' && props.item.cita.length > 0"
              class="existeCita"
              @click="goToVideo(props.item.cita_id[6])"
            >
              {{ props.item.cita[6] }}
            </span>
            <v-tooltip v-else bottom>
              <span slot="activator">{{ props.item.domingo }}</span>
              <span>{{ props.item.domingo_doctors }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'TablaSemanal',
  props: {
    headersTabla: {
      type: Array,
    },
    itemsTabla: {
      type: Array,
    },
    selectors: {
      type: Array,
    },
    label_selector: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    classTable: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    pagination: {
      itemsPerPage: 10,
    },
    seleccionado: 0,
  }),
  methods: {
    seleccionaItem(_indice, _dia) {
      // this.$emit('modificando', true);
      // console.log(this.itemsTabla[indice].profesional);
    },
    goToVideo(id) {
      this.$router.push('/cita/' + id);
    },
  },
};
</script>
<style scoped lang="css">
.fuera_rango {
  background: #fef9e7;
}
.rojo {
  background: #f7ece9;
}
.trabajando {
  background: #d5f5e3;
}

.existeCita {
  /*background: #fef9e7;*/
  cursor: pointer;
}

.selectorTabla {
  font-size: 12px;
  margin-top: 10px;
}
.v-label {
  /*font-size: 10px!important;*/
}
.dividirColumnas table th + th {
  border-left: 1px solid #dddddd;
}
.dividirColumnas table td + td {
  border-left: 1px solid #dddddd;
}
</style>
